<template>
  <Card class="w100" v-if="campaign">
    <div class="d-flex item"
         :class="activeCampaign && !activeAdSets ? 'open' : ''"
         @click="open_campaign()">
      <div class="img_folder d-flex">
        <img src="../../../assets/img/folder-adset.svg"  alt="folder">
      </div>
      <p class="text text-campaign">{{ $store.state.adsManager.initCampaignData.name }}</p>
    </div>
    <div v-for="adSet in campaign.ad_sets" :key="adSet.id" class="w100">
      <div v-if="activeCampaign" class="d-flex item"
           :title="getCampaignChanges ? 'Ad set editing is not available unless campaign changes are saved' : ''"
           :class="{
             'open': activeAdSets === adSet.id && !activeAds,
             'disabled': getCampaignChanges
           }"
           @click="open_ad_sets(adSet.id)">
        <div class="img">
          <template v-if="!is_imported">
            <img v-if="activeAdSets === adSet.id"
                 src="../../../assets/img/fill-arrow-down.svg"
                 alt="arrow-down">
            <img v-else
                 src="../../../assets/img/fill-arrow-right.svg"
                 alt="arrow-right">
          </template>
        </div>
        <div class="img_adSet d-flex">
          <img src="../../../assets/img/branch.svg" alt="branch">
        </div>
        <p class="text">{{ adSet.name }}</p>
      </div>
      <template v-if="!is_imported">
        <div v-for="ads in adSet.ads"
             :key="ads.id" class="item_ad"
             :class="ads.id === activeAds ? 'open' : ''"
             @click="open_ad(ads.id)">
          <div v-if="activeAdSets === adSet.id" class="d-flex align-items-center">
            <div class="img d-flex">
              <img src="../../../assets/img/megafon.svg" alt="ad">
            </div>
            <p class="text">{{ ads.name }}</p>
          </div>
        </div>
      </template>
    </div>
  </Card>
</template>

<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'TreeAdSet',
  props: {
    campaign: {
      required: true
    },
    activeSelect: {
      type: Object
    },
    is_imported: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('adsManager', [
      'getCampaignChanges'
    ]),
    activeCampaign: {
      get () {
        return this.activeSelect?.campaign
      },
      set (idx) {
        this.$emit('active_select', {
          campaign: idx,
          adSet: null,
          ad: null
        })
      }
    },
    activeAdSets: {
      get () {
        return this.activeSelect?.adSet
      },
      set (idx) {
        this.$emit('active_select', {
          campaign: this.activeCampaign,
          adSet: idx,
          ad: null
        })
      }
    },
    activeAds: {
      get () {
        return this.activeSelect?.ad
      },
      set (idx) {
        this.$emit('active_select', {
          campaign: this.activeCampaign,
          adSet: this.activeAdSets,
          ad: idx
        })
      }
    }
  },
  methods: {
    open_campaign () {
      this.activeCampaign = this.campaign.id
      this.$router.push({ name: 'EditPublishedCampaign', params: { campaign_id: this.campaign.id } })
    },
    open_ad_sets (idx) {
      if (!this.getCampaignChanges) {
        if (this.activeAdSets === idx && !this.activeAds) {
          this.activeAdSets = null
        } else {
          this.activeAdSets = idx
          this.$router.push({
            name: 'EditPublishedCampaignAdSet',
            params: {
              campaign_id: this.campaign.id,
              ad_set_id: idx
            }
          })
        }
      }
    },
    open_ad (idx) {
      this.activeAds = idx
      this.$router.push({
        name: 'EditPublishedCampaignAd',
        params: {
          campaign_id: this.campaign.id,
          ad_set_id: this.activeAdSets,
          ad_id: idx
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  align-items: center;
  border-left: 3px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  height: 55px;
    &:hover {
      border-left: 3px solid #99B4D7;
      border-radius: 4px;
      background-color: #EDF5FF;
    }
  .img {
    padding: 0 14px 0 20px;
  }
  .img_folder {
    padding: 0 10px 0 16px;
  }
  .img_adSet {
    padding-right: 14px;
  }
}
.item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background: none;
    border-left: 3px solid transparent;
  }
}
.item_ad {
  width: 100%;
  align-items: center;
  border-left: 3px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  .img {
    padding: 15px 10px 15px 52px;
  }
  &:hover {
    border-left: 3px solid #99B4D7;
    border-radius: 4px;
    background-color: #EDF5FF;
  }
}
.open {
  border-left: 3px solid #99B4D7;
  border-radius: 4px;
  background-color: #EDF5FF;
}

.text {
  color: #202428;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-campaign {
  color: #212529;
  font-size: 16px;
}
</style>
