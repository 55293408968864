































import Vue from 'vue'

import TreeAdSet from '@/components/ADSManager/EditCampaign/TreeAdSet.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'EditCampaign',
  components: {
    TreeAdSet,
    BasePagePreloader
  },
  data () {
    return {
      loading: true,
      is_imported: 1,
      activeSelect: {
        campaign: null,
        adSet: null,
        ad: null
      }
    }
  },
  props: {
    campaign_id: {
      required: true
    }
  },
  computed: {
    ...mapGetters('adsManager',
      {
        getCampaign: 'getCampaign',
        getObjective: 'getObjective'
      })
  },
  mounted () {
    window.scrollTo(0, 0)
    this.load_attribution_spec(this.campaign_id)
    this.load_campaign({
      campaignId: this.campaign_id,
      params: {
        'include[0]': 'ad_sets',
        'include[1]': 'ad_sets.ads',
        'include[2]': 'budget'
      }
    }).then((response) => {
      this.load_bidding({
        id: this.$route.params.campaign_id,
        optimize: this.getCampaign.optimize_for
      })
      this.load_adSet_spendCap({
        id: this.campaign_id,
        type: { budget_type: this.getCampaign.budget_type_time }
      })
      if (response?.data?.data?.attributes?.is_imported) {
        this.is_imported = response?.data?.data?.attributes?.is_imported
      } else {
        this.is_imported = 0
      }
    })['finally'](() => {
      this.loading = false
    })
    this.activeSelect = {
      campaign: this.campaign_id || null,
      adSet: this.$attrs.ad_set_id || null,
      ad: this.$attrs.ad_id || null
    }
  },
  methods: {
    ...mapActions('adsManager', [
      'load_campaign'
    ]),
    ...mapActions('budget', [
      'load_bidding',
      'load_adSet_spendCap',
      'load_attribution_spec'
    ])
  }
})
