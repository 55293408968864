



































import Vue from 'vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'

import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Publishing',
  props: {
    campaign_id: {
      required: true
    }
  },
  components: {
    BasePagePreloader
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    if (!this.getCampaign) {
      this.load_campaign({ campaignId: this.campaign_id, params: {} })
        .then(() => {
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
  computed: {
    ...mapGetters('adsManager', ['getCampaign'])
  },
  methods: {
    ...mapActions('adsManager', ['load_campaign'])
  }
})
